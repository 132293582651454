<template>
  <Title :text="t(`t1`)" />
  <div class="grid-column grid-column_span_5">
    <Promo
      class="promo_full_width"
      :src-pic="require(`@/assets/cover/social.jpg`)"
      :src-pic-mobile="require(`@/assets/cover/m/social.jpg`)"
    />
  </div>

  <div class="grid-column">
    <Share :title="t(`t1`)" />
  </div>
  <div class="grid-column grid-column_span_4 grid-column_row_2">
    <div class="h2-1" v-html="t(`t2`)"></div>
  </div>

  <div class="grid-column"></div>
  <div class="grid-column"></div>
  <div class="grid-column"></div>
  <div class="grid-column"></div>
  <div class="grid-column">
    <div class="content-pattern content-pattern_type_1"></div>
  </div>

  <div v-if="isMobile" class="grid-line"></div>

  <News />
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

import Title from "@/components/Title.vue";
import Promo from "@/components/Promo.vue";
import Share from "@/components/Share.vue";
import News from "@/components/News.vue";

export default defineComponent({
  name: "Socail",
  components: {
    Title,
    Promo,
    Share,
    News,
  },
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local",
    });

    return { t };
  },
});
</script>

<style lang="scss" scoped></style>

<i18n>
{
  "en": {
    "t1": "SOCIAL",
    "t2": "<p>The support of and participation in Social Projects is the main priority of the Office in seeking to collaborate and support the complementarity, effectiveness and diversity of civil society organisations and achieving community initiatives that are capable of facing challenges and responding to global changes.</p><p>We work with the best practices of Qatar and Russia to develop social collaborations between our countries. The office supports different types of projects and initiatives that aim to bring social change and support values.</p>",
  },
  "ru": {
    "t1": "СОЦИАЛЬНАЯ СФЕРА",
    "t2": "<p>Приоритетным направлением деятельности OKI является поддержка и участие в социальных проектах, в том числе в сотрудничестве с общественными организациями. OKI стремится поддерживать их разнообразие, взаимодополняемость и эффективность их деятельности, а также реализовать общественные инициативы, которые противостоят вызовам и реагируют на глобальные изменения.</p><p>Мы применяем передовые практики Катара и России для развития социального партнерства между нашими странами. OKI поддерживает различные категории социальных проектов и инициатив.</p>",
  },
  "ar": {
    "t1": "المجال الاجتماعي",
    "t2": "<p>أنشطة مكتب المعرفة والابتكار ذات الأولوية هي دعم المشاريع الاجتماعية والمشاركة فيها، بما في ذلك التعاون مع المنظمات العامة. يسعى مكتب المعرفة والابتكار جاهدا للحفاظ على تنوعه وتكامله وفعاليته في عملياته، فضلاً عن تنفيذ المبادرات المجتمعية التي تواجه التحديات وتستجيب للتغييرات العالمية.</p><p>نطبق أفضل ممارسات دولة قطر وروسيا لتطوير الشراكة الاجتماعية بين بلدينا. يدعم مكتب المعرفة والابتكار فئات مختلفة من المشاريع والمبادرات الاجتماعية.</p>",
  }
}
</i18n>
